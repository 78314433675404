import { useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { withFormik } from "formik";
import axios from "axios";
import { AuthenticationOptions } from "components";

import { Input, Text, Button, toast } from "samespace-zen";
import * as Yup from "yup";
import { useMutation } from "@apollo/client";
import { LOGIN } from "./queries";

import { BASE_SAMESPACE_API } from "utils/constants";

import { saveSession } from "utils/session";
import { setCookie } from "utils/cookies";

import jwt_decode from "jwt-decode";
import { groupBy } from "utils/common";

const LoginForm = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  validateForm,
  setTouched,
  setErrors,
}) => {
  const navigate = useNavigate();
  const [btnStates, setBtnStates] = useState({
    loading: false,
    success: false,
    failure: false,
  });
  const [Login] = useMutation(LOGIN);
  const redirectHandler = useCallback(() => {
    navigate.push("/forgot-password");
  }, [navigate]);

  const submitForm = useCallback(
    async (e) => {
      e.preventDefault();
      validateForm().then(async (errors) => {
        setTouched({ ...errors });
        setErrors({ ...errors });
        if (Object.keys(errors).length === 0) {
          setBtnStates({
            ...btnStates,
            loading: true,
          });
          try {
            const { data } = await Login({
              variables: {
                payload: {
                  email: values.email,
                  password: values.password,
                },
              },
            });
            const user = data.login.user;
            setCookie("userToken", user.userToken);
            const accounts = data.login.accounts;
            const serviceTokens = accounts
              .map((account) => {
                return account.spaces.map((space) => {
                  return {
                    instanceId: space.instanceId,
                    productName: space.productName,
                    spaceName: space.spaceName,
                    serviceToken: space.serviceToken,
                    orgName: account.orgName,
                    orgId: account.orgId,
                  };
                });
              })
              .flat();
            const switcherData = Object.keys(
              groupBy(serviceTokens, "orgId")
            ).map((item) => {
              return {
                [item]: {
                  name: groupBy(serviceTokens, "orgId")[item].find(
                    (orgs) => orgs.orgId === item
                  ).orgName,
                  samespace: groupBy(serviceTokens, "orgId")
                    [item].filter((item) => item.productName === "Samespace")
                    .map((item) => {
                      return {
                        instanceId: item.instanceId,
                        spaceName: item.spaceName,
                      };
                    }),
                  workspace:
                    groupBy(serviceTokens, "orgId")[item].filter(
                      (item) => item.productName === "Workspace"
                    ).length > 0
                      ? {
                          org: item,
                          activeOrgUrl: `https://${
                            jwt_decode(
                              groupBy(serviceTokens, "orgId")[item].filter(
                                (org) => org.productName === "Workspace"
                              )[0].serviceToken
                            ).services.find((item) => item.name === "proxy")
                              .prefix
                          }${
                            groupBy(serviceTokens, "orgId")[item].filter(
                              (org) => org.productName === "Workspace"
                            )[0].instanceId
                          }${
                            jwt_decode(
                              groupBy(serviceTokens, "orgId")[item].filter(
                                (org) => org.productName === "Workspace"
                              )[0].serviceToken
                            ).services.find((item) => item.name === "proxy")
                              .suffix
                          }.${process.env.REACT_APP_BASE_API || "ss.dev"}/`,
                        }
                      : null,
                },
              };
            });
            let switcherObj = Object.assign({}, ...switcherData);
            console.log(switcherObj, "asdasdasdas");
            setCookie("switcherData", JSON.stringify(switcherObj));
            const workSpaceTokens = serviceTokens.filter(
              (item) => item.productName === "Workspace"
            );
            const activeOrg = workSpaceTokens[0].orgId;
            const organizations = {};
            workSpaceTokens.forEach((workspace) => {
              organizations[workspace.orgId] = {
                name: workspace.orgName,
                type: "org",
                token: workspace.serviceToken,
              };
            });
            if (activeOrg) {
              const activeServiceToken = organizations[activeOrg].token;
              const apiInfo = jwt_decode(activeServiceToken).services.find(
                (item) => item.name === "proxy"
              );
              const workSpaceInstance =
                jwt_decode(activeServiceToken).instanceId;

              setCookie(
                "activeOrgUrl",
                `https://${apiInfo.prefix}${workSpaceInstance}${
                  apiInfo.suffix
                }.${process.env.REACT_APP_BASE_API || "ss.dev"}/`
              );
              setCookie("activeOrg", activeOrg);
              setCookie("organizations", JSON.stringify(organizations));
            }
            await serviceTokens
              .filter((item) => item.productName === "Samespace")
              .map((item) => {
                axios
                  .post(
                    `https://${item.instanceId}${BASE_SAMESPACE_API}`,
                    {
                      deviceType: "webapp",
                    },
                    {
                      headers: {
                        Authorization: item.serviceToken,
                      },
                    }
                  )
                  .then(({ data }) => {
                    setCookie("savedcloud");
                    saveSession({
                      authToken: data.token,
                      userId: data.identity,
                      cloudId: data.domainName,
                      baseUrl: `https://${item.instanceId}.samespace.com`,
                      ...data,
                    });
                  })
                  .catch((e) => {});
                return null;
              });

            setBtnStates({
              ...btnStates,
              success: true,
            });
            setTimeout(() => {
              window.location.href = "https://engage.samespace.com/stories";
            }, 1300);
          } catch (e) {
            console.log(e);
            toast.error(e.message || "Something went wrong");
            setBtnStates({
              ...btnStates,
              failure: true,
              loading: false,
            });
            setTimeout(() => {
              setBtnStates({
                ...btnStates,
                failure: false,
                loading: false,
              });
            }, 3000);
          }
        }
      });
    },
    [validateForm, values, Login, setErrors, setTouched, btnStates]
  );
  return (
    <form onSubmit={submitForm} className="flex flex-col items-center">
      <AuthenticationOptions type="sign in" />
      <div className="w-[400px] text-left mb-[32px]">
        <Input
          autoFocus
          type="text"
          name="email"
          label="Email"
          value={values.email}
          errorText={errors.email}
          error={touched.email && errors.email}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </div>
      <div className="w-[400px] text-left mb-[32px] relative">
        <span className="absolute right-0 cursor-pointer z-10">
          <Text
            onClick={redirectHandler}
            size="xs"
            color="primary"
            label="Forgot?"
            className="hover:underline"
          />
        </span>
        <Input
          name="password"
          label="Password"
          type="password"
          value={values.password}
          onChange={handleChange}
          errorText={errors.password}
          error={touched.password && errors.password}
          onBlur={handleBlur}
        />
      </div>
      <div className="w-full max-w-sm">
        <Button
          title="Sign In"
          full
          shape="rounded"
          loading={btnStates.loading}
          success={btnStates.success}
          failure={btnStates.failure}
        />
      </div>
      <div className="absolute bottom-4">
        <Text label="Copyright © 2021 Samespace Inc." color="muted" size="xs" />
      </div>
    </form>
  );
};

export default withFormik({
  enableReinitialize: true,
  validationSchema: Yup.object().shape({
    email: Yup.string().email("Enter a valid Email").required("Required"),
    password: Yup.string().required("Required"),
  }),
})(LoginForm);
