import { ErrorBoundary } from "components";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

import "./assets/styles.css";

import { ApolloProvider } from "@apollo/client";

import { client } from "graphql/Provider";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <ApolloProvider client={client}>
        <App />
      </ApolloProvider>
    </ErrorBoundary>
  </React.StrictMode>
);
