export const IconLinkedIn = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 2.85714C0 2.09938 0.301019 1.37266 0.836838 0.836838C1.37266 0.301019 2.09938 0 2.85714 0H17.1429C17.9006 0 18.6273 0.301019 19.1632 0.836838C19.699 1.37266 20 2.09938 20 2.85714V17.1429C20 17.9006 19.699 18.6273 19.1632 19.1632C18.6273 19.699 17.9006 20 17.1429 20H2.85714C2.09938 20 1.37266 19.699 0.836838 19.1632C0.301019 18.6273 0 17.9006 0 17.1429V2.85714ZM6.07143 16.75V7.71714H3.03571V16.75H6.07143ZM4.57 6.48286C5.60429 6.48286 6.27143 5.78286 6.27143 4.91714C6.23857 4.03286 5.60429 3.35 4.57 3.35C3.53571 3.35 2.86857 4.01714 2.86857 4.91714C2.86857 5.78286 3.53571 6.48286 4.53714 6.48286H4.57ZM10.7586 16.75V11.7C10.7586 11.4329 10.7929 11.1671 10.8586 10.9671C10.9744 10.6476 11.185 10.3711 11.4622 10.1745C11.7395 9.9779 12.0701 9.87067 12.41 9.86714C13.5114 9.86714 13.9443 10.7 13.9443 11.9171V16.75H16.9643V11.5671C16.9643 8.78286 15.4629 7.5 13.4943 7.5C11.8929 7.5 11.16 8.38286 10.7586 9V7.71714H7.74C7.77286 8.55 7.74 16.6829 7.74 16.75H10.7586Z"
      fill="#1C8DBE"
    />
  </svg>
);
export const IconGoogle = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_3682_9567)">
      <path
        d="M19.7879 10.2257C19.7879 9.56732 19.7296 8.94232 19.6296 8.33398H10.2129V12.0923H15.6046C15.3629 13.3257 14.6546 14.3673 13.6046 15.0757V17.5757H16.8212C18.7046 15.834 19.7879 13.2673 19.7879 10.2257Z"
        fill="#4285F4"
      />
      <path
        d="M10.2131 19.9999C12.9131 19.9999 15.1714 19.0999 16.8214 17.5749L13.6048 15.0749C12.7048 15.6749 11.5631 16.0415 10.2131 16.0415C7.60475 16.0415 5.39642 14.2832 4.60475 11.9082H1.28809V14.4832C2.92975 17.7499 6.30475 19.9999 10.2131 19.9999Z"
        fill="#34A853"
      />
      <path
        d="M4.60456 11.9092C4.39622 11.3092 4.28789 10.6676 4.28789 10.0009C4.28789 9.33424 4.40456 8.69258 4.60456 8.09258V5.51758H1.28789C0.604556 6.86758 0.212891 8.38424 0.212891 10.0009C0.212891 11.6176 0.604556 13.1342 1.28789 14.4842L4.60456 11.9092Z"
        fill="#FBBC05"
      />
      <path
        d="M10.2131 3.95833C11.6881 3.95833 13.0048 4.46667 14.0464 5.45834L16.8964 2.60833C15.1714 0.991667 12.9131 0 10.2131 0C6.30475 0 2.92975 2.25 1.28809 5.51667L4.60475 8.09167C5.39642 5.71667 7.60475 3.95833 10.2131 3.95833Z"
        fill="#EA4335"
      />
    </g>
    <defs>
      <clipPath id="clip0_3682_9567">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const IconApple = (
  <svg
    width="18"
    height="22"
    viewBox="0 0 18 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.9905 21.1141C13.8329 22.2721 12.5556 22.0916 11.3381 21.5456C10.0438 20.9886 8.86048 20.9533 7.49351 21.5456C5.79119 22.303 4.88771 22.0828 3.86248 21.1141C-1.92581 14.9714 -1.07145 5.61427 5.50712 5.262C7.10264 5.35007 8.21971 6.1713 9.15951 6.23955C10.5564 5.94672 11.8935 5.10789 13.3886 5.21797C15.1849 5.36768 16.5284 6.09864 17.4254 7.41304C13.7303 9.70278 14.6061 14.7226 18 16.1317C17.3208 17.9701 16.4493 19.7865 14.9884 21.1295L14.9905 21.1141ZM9.03135 5.19595C8.85835 2.46588 11.0049 0.220168 13.474 0C13.8136 3.1484 10.6974 5.50419 9.03135 5.19595Z"
      fill="black"
    />
  </svg>
);
