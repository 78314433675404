import { BrowserRouter, Routes, Route } from "react-router-dom";

import Home from "./pages/Home";
import OAuth from "pages/OAuth";
import Helmet from "react-helmet";

function App() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="AI-Powered Sales Acceleration & Customer Engagement Platform."
        />
        <title>Samespace-Login</title>
      </Helmet>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/oauth/redirect" element={<OAuth />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
