import { useCallback } from "react";
import { IconLinkedIn, IconApple, IconGoogle } from "../utils/icons";
import { toast } from "samespace-zen";

import { SIGN_IN_WITH_OAUTH } from "../pages/Home/queries";

import { useMutation } from "@apollo/client";

export const AuthenticationOptions = ({ type, inviteId, name }) => {
  const sentenceGenerator = useCallback(() => {
    if (inviteId) {
      return `Join ${name}`;
    } else {
      if (type === "signup") {
        return "Sign Up With";
      } else {
        return "Sign In";
      }
    }
  }, [inviteId, name, type]);
  const [signInWithOAuth] = useMutation(SIGN_IN_WITH_OAUTH);
  const oAuthSignUpHandler = useCallback(
    (provider) => {
      signInWithOAuth({
        variables: {
          payload: {
            provider: provider,
            inviteId: inviteId,
          },
        },
      })
        .then(({ data }) => {
          window.location = data.signInWithOAuth;
        })
        .catch((e) => {
          toast.error("Something went wrong. Try again later.");
        });
    },
    [inviteId, signInWithOAuth]
  );
  return (
    <div className="w-[400px]">
      <div className="flex justify-center mt-[60px] mb-[40px] w-full">
        <div className="text-[24px] text-[#333333] font-semibold">
          {sentenceGenerator()}
        </div>
      </div>
      <div className="grid grid-cols-3 gap-x-[12px]">
        <div
          className="w-full h-[44px] social-auth flex items-center justify-center cursor-pointer mr-[16px] rounded-full"
          onClick={oAuthSignUpHandler.bind(null, "LINKEDIN")}
        >
          <span>{IconLinkedIn}</span>
        </div>

        <div
          className="w-full h-[44px] social-auth flex items-center justify-center cursor-pointer mr-[16px] rounded-full"
          onClick={oAuthSignUpHandler.bind(null, "GOOGLE")}
        >
          <span>{IconGoogle}</span>
        </div>

        <div
          className="w-full h-[44px] social-auth flex items-center justify-center cursor-pointer rounded-full"
          onClick={oAuthSignUpHandler.bind(null, "APPLE")}
        >
          <span>{IconApple}</span>
        </div>
      </div>
      <div className="w-full or-divider my-[28px] relative">
        <div className="or-text">OR</div>
      </div>
    </div>
  );
};
