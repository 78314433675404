import { useEffect } from "react";
import { useQuery } from "@apollo/client";
import axios from "axios";

import { BASE_SAMESPACE_API } from "utils/constants";

import { saveSession } from "utils/session";
import { setCookie } from "utils/cookies";
import { GET_ACCESS_TOKEN } from "./queries";

import jwt_decode from "jwt-decode";

export const OAuth = () => {
  const params = new URLSearchParams(window.location.search);
  const { data } = useQuery(GET_ACCESS_TOKEN, {
    variables: {
      uuid: params.get("token"),
    },
  });

  useEffect(() => {
    const Login = async () => {
      const user = data.getAccessToken.user;
      setCookie("userToken", user.userToken);
      const accounts = data.getAccessToken.accounts;
      const serviceTokens = accounts
        .map((account) => {
          return account.spaces.map((space) => {
            return {
              instanceId: space.instanceId,
              productName: space.productName,
              serviceToken: space.serviceToken,
              orgName: account.orgName,
              orgId: account.orgId,
            };
          });
        })
        .flat();
      const workSpaceTokens = serviceTokens.filter(
        (item) => item.productName === "Workspace"
      );
      const activeOrg = workSpaceTokens[0].orgId;
      const organizations = {};
      workSpaceTokens.forEach((workspace) => {
        organizations[workspace.orgId] = {
          name: workspace.orgName,
          type: "org",
          token: workspace.serviceToken,
        };
      });
      if (activeOrg) {
        const activeServiceToken = organizations[activeOrg].token;
        const apiInfo = jwt_decode(activeServiceToken).services.find(
          (item) => item.name === "proxy"
        );
        const workSpaceInstance = jwt_decode(activeServiceToken).instanceId;

        setCookie(
          "activeOrgUrl",
          `https://${apiInfo.prefix}${workSpaceInstance}${apiInfo.suffix}.${
            process.env.REACT_APP_BASE_API || "ss.dev"
          }/`
        );
        setCookie("activeOrg", activeOrg);
        setCookie("organizations", JSON.stringify(organizations));
      }
      await serviceTokens
        .filter((item) => item.productName === "Samespace")
        .map((item) => {
          axios
            .post(
              `https://${item.instanceId}${BASE_SAMESPACE_API}`,
              {
                deviceType: "webapp",
              },
              {
                headers: {
                  Authorization: item.serviceToken,
                },
              }
            )
            .then(({ data }) => {
              setCookie("savedcloud");
              saveSession({
                authToken: data.token,
                userId: data.identity,
                cloudId: data.domainName,
                baseUrl: `https://${item.instanceId}.samespace.com`,
                ...data,
              });
            })
            .catch((e) => {});
          return null;
        });
      setTimeout(() => {
        window.location.href = "https://engage.samespace.com/stories";
      }, 1300);
    };
    if (data) {
      Login();
    }
  }, [data]);
  return (
    <div className="flex h-screen w-screen items-center justify-center">
      <svg
        class=" h-8 w-8 animate-spin text-accent"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          className="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          stroke-width="4"
        ></circle>
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
    </div>
  );
};

export default OAuth;
