import Background from "assets/LoginCircles/hero-image.svg";

export const AuthWrapper = ({ body }) => {
  return (
    <>
      <div className="flex items-center justify-between h-[100vh]">
        <div className="lg:w-3/5 h-full flex items-center justify-center">
          <div className="w-full h-full flex items-center relative">
            <img src={Background} alt="Samespace|Asset" />
          </div>
        </div>
        <div className="lg:w-2/5 text-center px-4 flex items-center justify-center">
          {body}
        </div>
      </div>
    </>
  );
};
