import { Component } from "react";
import { Text } from "samespace-zen";

export class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="h-screen w-screen flex items-center justify-center flex-col">
          <img
            src={require("assets/Images/boat.png").default}
            alt="Sinking Boat| Samespace"
          />
          <Text className="mt-4" size="lg" weight="bold" label="We’re sorry" />
          <Text
            className="text-center mt-2"
            size="base"
            color="muted"
            label={
              <>
                Something went wrong with your request.
                <br /> Please try again later or contact us.
              </>
            }
          />
        </div>
      );
    }

    return this.props.children;
  }
}
