import { getAllCookie, setCookie, removeCookie } from "./cookies";

const getBooleanString = (val) => {
  if (val && (val === true || val === "true")) {
    return "true";
  } else {
    return "false";
  }
};

export function saveSession(data) {
  const allCookies = getAllCookie();
  const { accList } = allCookies;
  const { cloudId } = data;
  const sessionData = {
    savedcloud: cloudId,
    authToken: data.authToken,
    refreshToken: data.refreshToken,
    username: data.username,
    name: data.name,
    domainId: data.domainId,
    userId: data.userId,
    emailVerified: data.emailVerified,
    BASE_URL: data.BASE_URL || data.baseUrl,
    admin: getBooleanString(data.admin),
    manage: getBooleanString(data.manage),
    insights: getBooleanString(data.insights),
    ivr: getBooleanString(data.ivr),
    campaign: getBooleanString(data.campaign),
    sipTrunk: getBooleanString(data.sipTrunk),
    mediaServer: getBooleanString(data.mediaServer),
    verifyPhone: getBooleanString(data.verifyPhone),
    newAnalytics: getBooleanString(data.newAnalytics),
  };
  if (data.firstUserLogin) {
    setCookie("newUser", true);
  }
  if (data.daysToVerify) {
    sessionData.daysToVerify = data.daysToVerify || "0";
  }
  for (const [key, value] of Object.entries(sessionData)) {
    setCookie(key, value);
  }
  setCookie(`account_${cloudId}`, {
    ...sessionData,
    updatedAt: Date.now(),
  });

  let updateAccList = accList || [];
  if (updateAccList.includes(cloudId)) {
    updateAccList = updateAccList.filter((o) => o !== cloudId);
  }
  if (updateAccList.length >= 5) {
    const extraClouds = updateAccList.slice(5);
    extraClouds.forEach((acc) => {
      removeCookie(`account_${acc}`);
    });
    updateAccList = updateAccList.splice(0, 5);
  }
  updateAccList.unshift(cloudId);
  setCookie(`accList`, updateAccList);
}
