import { gql } from "@apollo/client";

export const SIGN_IN_WITH_OAUTH = gql`
  mutation SignInWithOAuth($payload: inputsignInWithOAuth!) {
    signInWithOAuth(payload: $payload)
  }
`;

export const LOGIN = gql`
  mutation Login($payload: inputLogin!) {
    login(payload: $payload)
  }
`;
